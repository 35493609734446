<template>
  <div>
    <div v-if="selectedFields.length > MAX_DEFAULT_FIELDS">
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >Please select fewer than {{ MAX_DEFAULT_FIELDS }} pastures using the
            top navigation tools.</span
          >
        </v-card-text>
      </v-card>
    </div>
    <div v-else-if="selectedFields.length > 0">
      <!-- Forage Info -->
      <div id="input-card-container">
        <v-card class="my-auto mx-auto" v-if="showPracticesView || showSaveView">
          <v-card-text class="min-step-height" v-if="showPracticesView">
            <v-row class="mx-1 mt-7">
              <div class="text-h5">Add Ranching for {{ year }}</div>
              <v-btn class="ml-auto" @click="handleCancelClick()">
                <v-icon>mdi-close</v-icon> Cancel
              </v-btn>
              <v-btn
                class="ml-4"
                color="green lighten-4"
                @click="handlePreSave()"
                >Save and Continue</v-btn
              >
            </v-row>

            <v-row>
              <v-col md="12">
                <v-card
                  v-for="ranching in ranchingData"
                  :key="ranching.idx"
                  class="mt-3"
                  elevation="6"
                >
                  <v-card-title
                    class="cursor blue-grey lighten-5"
                    @click="ranching.showAllInfo = !ranching.showAllInfo"
                  >
                    Entry #{{ ranchingData.indexOf(ranching) + 1 }}
                    <v-icon
                      v-if="!ranching.showAllInfo"
                      @click="ranching.showAllInfo = true"
                      >mdi-chevron-right</v-icon
                    >
                    <v-icon v-else @click="ranching.showAllInfo = false"
                      >mdi-chevron-down</v-icon
                    >
                    <v-tooltip top
                      ><template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="ml-auto"
                          :color="
                            isRanchingComplete(ranching) ? 'green accent-4' : 'red darken-1'
                          "
                        >
                          {{
                            isRanchingComplete(ranching)
                              ? "mdi-check-circle-outline"
                              : "mdi-alert-circle-outline"
                          }}
                        </v-icon> </template
                      ><span>{{
                        isRanchingComplete(ranching)
                          ? "Application is good to go!"
                          : "You're missing a few fields, please complete this application to save and continue!"
                      }}</span></v-tooltip
                    >
                    <v-btn
                      class="ml-2"
                      @click.stop="handleRemoveRanching(ranchingData.indexOf(ranching))"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  
                  <v-card-text v-if="ranching.showAllInfo">
                    <v-row align="end" class="mt-2">
                      <v-col md="2" class="pt-1">
                        <div class="mb-2">
                          <b>Start Date</b>
                        </div>
                        <div class="input-holder">
                          <v-menu
                            v-model="ranching.startMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="ranching.grazings.date_start"
                                label="Start Date"
                                dense
                                outlined
                                readonly
                                hide-details
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="ranching.grazings.date_start"
                              :picker-date.sync="ranching.grazings.startPicker"
                              :min="year + '-01-01'"
                              :max="year + '-12-31'"
                              @input="ranching.startMenu = false"
                              scrollable
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </v-col>
                      <v-col md="2" class="pt-1">
                        <div class="mb-2">
                          <b
                            >End Date</b
                          >
                        </div>
                        <div class="input-holder">
                          <v-menu
                            v-model="ranching.endMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="ranching.grazings.date_end"
                                label="End Date"
                                dense
                                outlined
                                readonly
                                hide-details
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="ranching.grazings.date_end"
                              :picker-date.sync="ranching.grazings.endPicker"
                              :min="year + '-01-01'"
                              :max="year + '-12-31'"
                              @input="ranching.endMenu = false"
                              scrollable
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </v-col>
                      <v-col md="4" class="pt-1">
                        <div class="mb-2">
                          <b class="text-h7"> Number of Animals </b>
                        </div>
                        <div class="input-holder">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            clearable
                            label="Enter Number of Animals"
                            v-model="ranching.stocking.count_animals"
                          ></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- Livestock -->
                    <v-row align="end" class="mt-3">
                      <v-col md="12" class="py-0">
                        <span class="text-h6">Livestock Details</span>
                      </v-col>
                      <v-col md="4" class="pt-1">
                        <div class="mb-2">
                          <b class="text-h7">
                            Species
                          </b>
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Select Species"
                            :items="speciesChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="ranching.livestock.species"
                            clearable
                          >
                          </v-select>
                        </div>
                      </v-col>
                      <v-col md="4" class="pt-1">
                        <div class="mb-2">
                          <b class="text-h7">
                            Animal Details
                          </b>
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Select Animal Type Detail"
                            :items="animalDetailChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="ranching.livestock.animal_details"
                            clearable
                          >
                          </v-select>
                        </div>
                      </v-col>
                      <v-col md="2" class="pt-1">
                        <div class="mb-2">
                          <b class="text-h7"> Average Weight </b>
                        </div>
                        <div class="input-holder">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            clearable
                            label="Enter Average Weight"
                            v-model="ranching.livestock.average_weight"
                          ></v-text-field>
                        </div>
                      </v-col>
                      <v-col md="2" class="pt-1">
                        <div class="mb-2">
                          <b class="text-h7">
                            Weight Units
                          </b>
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Select Weight Unit"
                            :items="weightUnitChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="ranching.livestock.weight_unit"
                            clearable
                          >
                          </v-select>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- Feed -->
                    <v-row align="end" class="mt-3">
                      <v-col md="12" class="py-0">
                        <span class="text-h6">Feeding Details</span>
                      </v-col>
                      <v-col md="4" class="pt-1">
                        <div class="mb-2">
                          <b class="text-h7">
                            Feed Additive
                          </b>
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Select Feed Additive"
                            :items="additiveChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="ranching.feed.additive"
                            clearable
                          >
                          </v-select>
                        </div>
                      </v-col>
                      <v-col md="4" class="pt-1">
                        <div class="mb-2">
                          <b class="text-h7">
                            Feed Method
                          </b>
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Select Feed Method"
                            :items="methodChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="ranching.feed.method"
                            clearable
                          >
                          </v-select>
                        </div>
                      </v-col>
                      <v-col md="2" class="pt-1">
                        <div class="mb-2">
                          <b class="text-h7"> Feed Amount </b>
                        </div>
                        <div class="input-holder">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            clearable
                            label="Enter Feed Amount"
                            v-model="ranching.feed.amount"
                          ></v-text-field>
                        </div>
                      </v-col>
                      <v-col md="2" class="pt-1">
                        <div class="mb-2">
                          <b class="text-h7">
                            Feed Units
                          </b>
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Select Unit"
                            :items="unitsChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="ranching.feed.unit"
                            clearable
                          >
                          </v-select>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row align="end" class="mt-3">
                      <v-col md="4"></v-col>
                      <v-col md="4">
                        <div class="mb-2">
                          <b class="text-h7"> Days on Additive </b>
                        </div>
                        <div class="input-holder d-flex">
                          <v-text-field
                            dense
                            outlined
                            clearable
                            label="Enter Days on Additive"
                            v-model="ranching.feed.days_on_additive"
                          > </v-text-field>
                          <div class="mt-2 ml-2">
                            <span class="text-h7"> days </span>
                          </div>
                        </div>
                      </v-col>
                      <v-col md="2" class="pt-1">
                        <div class="mb-2">
                          <b class="text-h7"> Expected Daily DMI </b>
                        </div>
                        <div class="input-holder">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            clearable
                            label="Enter Expected DMI"
                            v-model="ranching.feed.expected_daily_dmi"
                          ></v-text-field>
                        </div>
                      </v-col>
                      <v-col md="2" class="pt-1">
                        <div class="mb-2">
                          <b class="text-h7">
                            DMI Units
                          </b>
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Select DMI Unit"
                            :items="dmiUnitsChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="ranching.feed.dmi_unit"
                            clearable
                          >
                          </v-select>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- Grazings -->
                    <v-row class="mt-3">
                      <v-col md="12" class="py-0">
                        <span class="text-h6">Foraging and Grazing Details</span>
                      </v-col>
                      <v-col md="4" class="pt-1">
                        <div class="mb-2">
                          <b class="text-h7">
                            Primary Forage
                          </b>
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Select Primary Forage"
                            :items="primaryForageChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="ranching.forage.primary_forage"
                            clearable
                          >
                          </v-select>
                        </div>
                      </v-col>
                      <v-col md="2" class="pt-1">
                        <div class="mb-2">
                          <b class="text-h7"
                            >Grazed or Fed</b
                          >
                        </div>
                        <div>
                          <v-btn
                            rounded
                            outlined
                            @click="ranching.grazings.grazed_fed = 'Grazed'"
                            :input-value="ranching.grazings.grazed_fed === 'Grazed'"
                            >Grazed</v-btn
                          >
                          <v-btn
                            class="ml-4"
                            rounded
                            outlined
                            @click="ranching.grazings.grazed_fed = 'Fed'"
                            :input-value="ranching.grazings.grazed_fed === 'Fed'"
                            >Fed</v-btn
                          >
                        </div>
                      </v-col>
                      <v-col md="2" class="pt-1">
                        <div class="mb-2">
                          <b class="text-h7"
                            >Rotational Grazed</b
                          >
                        </div>
                        <div>
                          <v-btn
                            rounded
                            outlined
                            @click="ranching.grazings.rotational_grazed = 'true'"
                            :input-value="ranching.grazings.rotational_grazed === 'true'"
                            >Yes</v-btn
                          >
                          <v-btn
                            class="ml-4"
                            rounded
                            outlined
                            @click="ranching.grazings.rotational_grazed = 'false'"
                            :input-value="ranching.grazings.rotational_grazed === 'false'"
                            >No</v-btn
                          >
                        </div>
                      </v-col>
                      <v-col md="3" class="pt-1">
                        <div class="mb-2">
                          <b class="text-h7"> Rest Period </b>
                        </div>
                        <div class="input-holder d-flex">
                          <v-text-field
                            dense
                            outlined
                            clearable
                            label="Enter Any Rest Period"
                            v-model="ranching.grazings.rest_period"
                          > </v-text-field>
                          <div class="mt-2 ml-2">
                            <span class="text-h7"> days </span>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="4" class="pt-1">
                        <div class="mb-2">
                          <b class="text-h7">
                            Feed Ration Type
                          </b>
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Select Feed Ration Type"
                            :items="feedRationTypeChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="ranching.grazings.feed_ration_type"
                            clearable
                          >
                          </v-select>
                        </div>
                      </v-col>
                      <v-col md="4" class="pt-1">
                        <div class="mb-2">
                          <b class="text-h7">Feed Ration % Crude Protein</b>
                        </div>
                        <div class="input-holder">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            clearable
                            label="Enter Feed Ration % Crude Protein"
                            v-model="ranching.grazings.feed_ration_percent_crude_protein"
                          ></v-text-field>
                        </div>
                      </v-col>
                      <v-col md="4" class="pt-1">
                        <div class="mb-2">
                          <b class="text-h7">
                            Percent % Edible Oil
                          </b>
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Select Percent % Edible Oil"
                            :items="percentEdibleOilChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="ranching.grazings.percent_edible_oil"
                            clearable
                          >
                          </v-select>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-btn class="mt-4" block @click="handleAddEventClick">
                  Add New Entry<v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>

      <!-- Fields, Data Comp, and Add Practices -->
      <v-row class="mt-4 mb-2 row-margin-correct" v-if="!showPracticesView && !showSaveView">
          <div>
            <span class="text-h5 ml-1"
              >{{ ranchingSettingsByField.length }} fields</span
            >
            <span class="text-h5 ml-2"
              >({{ dataCompletionForYear }}% Complete)</span
            >
          </div>
        <div class="ml-auto d-flex">
          <div>
            <v-btn
              class="mx-1"
              text
              v-for="y in yearListModified"
              :key="y.idx"
              @click="handleSeasonSelection(y)"
              :color="year == y ? 'green' : ''"
              >{{ y }}</v-btn
            >
          </div>
          <div class="text-container ml-2">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="tableFilterText"
              label="Filter Fields"
            ></v-text-field>
          </div>
          <div class="ml-2">
            <v-btn v-if="showPracticesView === false && showSaveView === false && showSummaryView === false"
              :class="'white--text'"
              :color="'green'"
              :disabled="fieldsInSelection.length === 0"
              x-large
              @click="handleAddPracticesClick"
              >Add Practices</v-btn
            >
          </div>
        </div>
      </v-row>

      <!-- Pasture Filtering Table -->
      <v-row class="pt-3 pasture-filtering-table">
        <v-col>
          <div class="mb-2">
            <b class="text-h7">
              Select Pasture to Add Practices To:
            </b>
          </div>
          <v-card>
            <v-card-text>
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="three"></th>
                    <th class="three"></th>
                    <th class="ten">Pasture Name</th>
                    <th class="ten">Ranch</th>
                    <th class="ten">Grazing Events</th>
                    <th class="ten">Grazing Events Completed</th>
                    <th class="ten">Percent Completed</th>
                    <th class="ten"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="field in ranchingSettingsByFieldFiltered"
                    :key="field.idx"
                  >
                    <td>
                      <input
                        @click="fieldBoxClicked(field)"
                        type="radio"
                        :value="field"
                        v-model="selectedField"
                      />
                    </td>
                    <td>
                      <img :src="field.fieldImg" style="width: 50px" />
                    </td>
                    <td>{{ field.fieldName }}</td>
                    <td class="overflow-hidden">{{ field.farmName }}</td>
                    <td>{{ field.grazingEventsTotal }}</td>
                    <td>{{ field.grazingEventsCompleted }}</td>
                    <td>{{ field.grazingEventsCompletedPercent }} %</td>
                    <td>
                      <button 
                        @click="handlePastureClick(field.fieldId)"
                      >Edit Data</button>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- No Fields Selected Alert -->
      <AlertErrorModal
        v-if="alertErrorModalOpen"
        titleText="No Fields Selected"
        :errorMessage="[
          'You currently have no fields selected to save management practices for. Please select a field to continue.',
        ]"
        @close-modal="alertErrorModalOpen = false"
      />

      <ConfirmModal
        v-if="showSaveConfirmModal"
        titleText="Confirm Saving Entry"
        :confirmText="saveConfirmText"
        @confirm="handleSave"
        @close-modal="showSaveConfirmModal = false"
      />

      <ProgressModal
        v-if="showSaveView"
      />

      <!-- Wizard Confirm Modal -->
      <WizardConfirmModal
        v-if="showConfirmModal"
        :confirmationField="confirmationField"
        :confirmationData="confirmationApplications"
        :wantedKeys="[
          'crop_id',
          'primary_forage',
        ]"
        :year="year"
        :loadState="loadState"
        @close-template-modal="handleCloseConfirm"
        @confirm-data="handleModalConfirm"
      />

      <!-- Redirect -->
      <WizardRedirectModal
        v-if="showRedirectModal"
        :redirectField="redirectField"
        :year="year"
        @close-template-modal="handleCloseRedirect"
        @redirect-confirm="handleRedirectConfirm"
      />
    </div>
    <div v-else>
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >No Fields Selected. Please select a field to apply management
            practices.</span
          >
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import _ from "lodash"
import { mapActions, mapMutations, mapState, mapGetters } from "vuex"
import {
  MAX_DEFAULT_FIELDS,
} from "@/constants/defaults"
import { Defaults, Organization } from "@/store/modules"
import {
  CROP_DISPLAY_NAME,
  CROP_KEY,
  CROP_ID_KEY,
} from "@/constants"
import AlertErrorModal from "@/components/modals/AlertErrorModal"
import WizardConfirmModal from "@/components/modals/WizardConfirmModal"
import WizardRedirectModal from "@/components/modals/WizardRedirectModal"
import ConfirmModal from "@/components/modals/ConfirmModal"
import ProgressModal from "@/components/modals/ProgressModal"
import { numberValidator } from "@/utility"
import PracticeChoiceDropdown from "@/components/dropdowns/PracticeChoiceDropdown"
import { dupeCheck, mapPayloadKeysToUserInput } from "./utils"
import UploadsAPI from "@/api/UploadsAPI"
import router from "@/router"

export default {
  name: "RanchingWizard",
  props: {
    selectedFields: { type: Array },
    year: { type: String },
    yearList: { type: Array },
    dataCompletion: { type: Array },
    dataCompletionMaster: { type: Object },
  },
  components: {
    AlertErrorModal,
    WizardConfirmModal,
    WizardRedirectModal,
    PracticeChoiceDropdown,
    ConfirmModal,
    ProgressModal
  },
  data() {
    return {
      cropDisplay: CROP_DISPLAY_NAME,
      cropChoices: Object.values(CROP_KEY),
      MAX_DEFAULT_FIELDS: MAX_DEFAULT_FIELDS,

      //   Ranching data
      ranchingData: [],
      stackPractices: true,
      // table related
      showSelectedFields: true,
      fieldsInSelection: [],
      filterCrops: [],
      filterCropsReflection: [],
      tableFilterText: "",
      selectAllBox: true,
      // full validation for application completeness
      showIncompleteModal: false,
      incompleteForages: null,
      alertErrorModalOpen: false,
      // confirmation
      confirmationField: null,
      confirmationApplications: null,
      showConfirmModal: false,
      showSaveConfirmModal: false,
      loadState: null,
      // redirection
      showRedirectModal: false,
      redirectField: null,
      // table year for copying from historical info
      tableYear: null,
      rules: {
        date: value => {
          var dateRegex = /^\d{4}-\d{2}-\d{2}$/
          return dateRegex.test(value) || "YYYY-MM-DD"
        },
      },
      numberValidator,
      //   select crop type UX, disables selecting new fields when selecting by crop tile
      cropSelect: null,
      //   new UI
      showPracticesView: false,
      showSaveView: false,
      showSummaryView: false,
      summaryFields: null,
      summaryKeys: null,
      summaryData: null,
      selectedField: null,
    }
  },
  computed: {
    ...mapState({
      ranchingSettings: state => state.Defaults.newFieldSettings,
    }),
    ...mapGetters({
      practiceChoices: Defaults.Getters.getPracticeChoices,
    }),
    speciesChoices() {
      return this.practiceChoices["pasture_livestock_species"]
    },
    primaryForageChoices() {
      return this.practiceChoices["pasture_forage_primary_forage"]
    },
    animalDetailChoices() {
      return this.practiceChoices["pasture_livestock_animal_details"]
    },
    weightUnitChoices() {
      return this.practiceChoices["pasture_livestock_weight_units"]
    },
    dmiUnitsChoices() {
      return this.practiceChoices["pasture_feed_dmi_units"]
    },
    additiveChoices() {
      return this.practiceChoices["pasture_feed_additive"]
    },
    methodChoices() {
      return this.practiceChoices["pasture_feed_method"]
    },
    unitsChoices() {
      return this.practiceChoices["pasture_feed_units"]
    },
    feedRationTypeChoices() {
      return this.practiceChoices["pasture_grazing_feed_ration_type"]
    },
    percentEdibleOilChoices() {
      return this.practiceChoices["pasture_grazing_percent_edible_oil"]
    },
    // cropChoicesFiltered() {
    //   if (this.cropSelect != null) {
    //     const filteredCropList = Object.entries(CROP_KEY)
    //       .filter(o => {
    //         if (this.cropSelect.length > 1) {
    //           return this.cropSelect.includes(o[0])
    //         } else {
    //           return true
    //         }
    //       })
    //       .map(list => list[1])
    //     return filteredCropList
    //   } else {
    //     const selectedProtSettings = this.forageSettingsByField.filter(
    //       field => this.fieldsInSelection.includes(parseInt(field.fieldId))
    //     )
    //     const selectedCrops = new Set(
    //       selectedProtSettings.flatMap(field => field.crops)
    //     )
    //     const selectedCropNames = Array.from(selectedCrops).map(
    //       c => CROP_KEY[c]
    //     )
    //     return selectedCropNames.filter(i => i != undefined)
    //   }
    // },
    // hasPracticeData() {
    //   return this.forageData.length > 0 ? true : false;
    // },
    dataCompletionForYear() {
      if (!this.year || this.selectedFields.length == 0) {
        return 0
      }
      let yearDataComps = this.dataCompletionMaster["Forage"].map(
        o => {
          if ("by_year" in o.data_comp) {
            let temp = o.data_comp.by_year.find(
              yearObj => yearObj.year == this.year
            )
            if (temp) {
              return temp.avg_for_year
            }
          } else {
            return 0
          }
        }
      )
      let average = array => array.reduce((a, b) => a + b) / array.length
      if (yearDataComps.length > 0) {
        return Math.ceil(average(yearDataComps))
      } else {
        return 0
      }
    },
    // fieldLocks() {
    //   let fieldLocks = {}
    //   for (const field of this.forageSettingsByField) {
    //     fieldLocks[field.fieldId] = field.fieldLocked
    //   }
    //   return fieldLocks
    // },
    saveConfirmText() {
      return `
        Are you sure you want to add entries on the selected field for ${this.year}?`
    },
    ranchingSettingsByField() {
      let ranchingDict = {}
      let yearInConsideration = this.year
      Object.entries(this.ranchingSettings).forEach(ranchingDefault => {
        const fieldKey = ranchingDefault[0]
        const selField = this.selectedFields.find(field => field.id == fieldKey)
        if (!selField) {
          return
        }

        ranchingDict[fieldKey] = {}
        const fieldSettingObj = ranchingDefault[1]
        const idSettings = {
          fieldId: fieldKey,
          fieldName: selField.name,
          farmName: selField.farm.name,
          fieldImg: selField.boundary_image,
          opsId: fieldSettingObj.operation_id,
          grazingEventsTotal: 0,
          grazingEventsCompleted: 0,
          grazingEventsCompletedPercent: '0.00',
        }
        const hasYearData = fieldSettingObj.year_data.some(
          y => y.year == yearInConsideration
        )

        ranchingDict[fieldKey] = idSettings;
        ranchingDict[fieldKey]["noYearData"] = true;

        fieldSettingObj.year_data.forEach(yearData => {
          if (!hasYearData) {
            // ranchingDict[fieldKey] = idSettings
            // ranchingDict[fieldKey]["crops"] = ["No Data"]
            // ranchingDict[fieldKey]["cropNames"] = ["No Data"]
            ranchingDict[fieldKey]["noYearData"] = true
          } else if (yearData.year == yearInConsideration) {
            ranchingDict[fieldKey] = idSettings
            ranchingDict[fieldKey]["grazingEventsTotal"] = yearData.grazing_events_total
            ranchingDict[fieldKey]["grazingEventsCompleted"] = yearData.grazing_events_completed
            ranchingDict[fieldKey]["grazingEventsCompletedPercent"] = (yearData.grazing_events_total ? ((yearData.grazing_events_completed / yearData.grazing_events_total) * 100).toFixed(2) : '0.00')
            // ranchingDict[fieldKey]["crops"] = []
            // ranchingDict[fieldKey]["crops"] = []
            // ranchingDict[fieldKey]["cropNames"] = []
            // ranchingDict[fieldKey]["cropSearch"] = ""
            // ranchingDict[fieldKey]["plantings"] = []
            // ranchingDict[fieldKey]["forageInfo"] = []

            // ranchingDict[fieldKey]["isSelected"] =
            //   this.fieldsInSelection.includes(parseInt(fieldKey)) ? true : false

            const cultivations = yearData.cultivations.sort(
              (a, b) => a.arva_crop_id - b.arva_crop_id
            )
            cultivations.forEach(cultivation => {
              // ranchingDict[fieldKey]["crops"].push(cultivation.arva_crop_id)
              // ranchingDict[fieldKey]["cropNames"].push(
              //   CROP_DISPLAY_NAME[cultivation.arva_crop_id]
              // )
              // ranchingDict[fieldKey]["cropSearch"] =
              //   ranchingDict[fieldKey]["cropSearch"] +
              //   CROP_DISPLAY_NAME[cultivation.arva_crop_id]
              // ranchingDict[fieldKey]["plantings"] = ranchingDict[fieldKey][
              //   "plantings"
              // ].concat(cultivation.plantings)
              // cultivation.forage.forEach(forage =>
              //   ranchingDict[fieldKey]["forageInfo"].push(forage)
              // )

              // ranchingDict[fieldKey]["crops"].sort()
              // ranchingDict[fieldKey]["cropNames"].sort()
              // ranchingDict[fieldKey]["fieldLocked"] =
              //   cultivation.year_data_locked
              ranchingDict[fieldKey]["noYearData"] = false
            })
          }
        })
      })
      return Object.values(ranchingDict)
    },
    ranchingSettingsByFieldFiltered() {
      let arrayToReturn = _.cloneDeep(this.ranchingSettingsByField)

      if (this.tableFilterText) {
        const searchString = this.tableFilterText.toLocaleLowerCase()
        arrayToReturn = arrayToReturn.filter(field =>
          field.fieldName.toLowerCase().includes(searchString)
        )
      }

      // if (this.cropSelect) {
      //   arrayToReturn = arrayToReturn.filter(field => {
      //     const found = this.cropSelect.every(
      //       c => field.crops.indexOf(parseInt(c)) >= 0
      //     )
      //     return found
      //   })
      // }

      const sortColumns = ["fieldName"]
      const sortDirections = ["desc", "asc"]

      return _.orderBy(arrayToReturn, sortColumns, sortDirections)
    },
    // historicalPlantings() {
    //   const plantingsGrouped = _(this.forageSettingsByField)
    //     .groupBy("crops")
    //     .map((items, crops) => ({
    //       crops: crops,
    //       fieldIds: _.map(items, "fieldId"),
    //       fieldNames: _.map(items, "fieldName"),
    //       noYearData: _.map(items, "noYearData")[0],
    //     }))
    //     .value()
    //   return plantingsGrouped
    // },
    // yearPriorOne() {
    //   return String(Number(this.year) - 1)
    // },
    // yearExtendedOne() {
    //   return String(Number(this.year) + 1)
    // },
    yearListModified() {
      return this.yearList.filter(o => !isNaN(o))
    },
  },
  methods: {
    ...mapActions({
      updateYear: Organization.Actions.updateYear,
      // updateRanching: Defaults.Actions.updateRanching,
    }),
    ...mapMutations({
      setRecentWizardData: Defaults.Mutations.setRecentWizardData,
    }),
    // disableCheckbox(noYearData) {
    //   if (noYearData) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    // fieldChecked(field, e) {
    //   if (
    //     this.fieldsInSelection.includes(parseInt(field.fieldId)) &&
    //     e == false
    //   ) {
    //     this.fieldsInSelection = this.fieldsInSelection.filter(
    //       f => f != parseInt(field.fieldId)
    //     )
    //   }
    //   if (
    //     e == true &&
    //     this.fieldsInSelection.indexOf(parseInt(field.fieldId)) == -1
    //   ) {
    //     this.fieldsInSelection.push(parseInt(field.fieldId))
    //   }
    // },
    fieldBoxClicked(field) {
      this.selectedField = field;
      this.fieldsInSelection = [field]
    },
    // mapCropNames(cropIdString) {
    //   if (cropIdString == "No Data") {
    //     return cropIdString
    //   } else if (cropIdString.includes(",")) {
    //     const cropIdVals = cropIdString.split(",")
    //     return cropIdVals.map(v => this.cropDisplay[v]).join(", ")
    //   } else {
    //     return this.cropDisplay[cropIdString]
    //   }
    // },
    handleAddEventClick() {
      // const cropValue =
      //   this.cropSelect != null && this.cropSelect.length == 1
      //     ? CROP_KEY[parseInt(this.cropSelect[0])]
      //     : null
      this.ranchingData.push(this.blankRanchingData());
    },
    // handleAddMoreForage() {
    //   this.showSummaryView = false
    //   this.showPracticesView = true
    // },
    handleAddPracticesClick() {
      if (this.showPracticesView == false) {
        this.showSaveView = false
        this.showSummaryView = false
        this.showPracticesView = true

        // const cropValue =
        //   this.cropSelect != null && this.cropSelect.length == 1
        //     ? CROP_KEY[parseInt(this.cropSelect[0])]
        //     : null
        const newRanchingData = this.blankRanchingData()
        // newRanchingData.crop_id = cropValue
        this.ranchingData = [newRanchingData];
      }
    },
    handleCancelClick() {
      this.showSaveView = false
      this.showSummaryView = false
      this.showPracticesView = false
      this.ranchingData = []
    },
    // handleCopy(field) {
    //   this.cropSelect = null;

    //   const copiedData = this.extractForageData(field)
    //   for (const practice of copiedData) {
    //     if (!this.cropChoicesFiltered.includes(practice.crop_id)) {
    //       practice.crop_id = null
    //     }
    //   }
    //   this.forageData = copiedData

    //   this.showSummaryView = false
    //   this.showPracticesView = true

    //   const myElement = document.getElementById("input-card-container")
    //   myElement.scrollIntoView({
    //     behavior: "smooth",
    //     block: "end",
    //     inline: "nearest",
    //   })
    // },
    // extractForageData(field) {
    //   const numFieldForages = field.forageInfo.length

    //   let fieldForageData = [];
    //   for (let i = 0; i < numFieldForages; i++) {
    //     fieldForageData.push({
    //       crop_id: CROP_KEY[field.forageInfo[i].crop_id.value],
    //       primary_forage: field.forageInfo[i].primary_forage.value,
    //       // rate: field.forageInfo[i].rate.value,
    //       // unit: field.forageInfo[i].unit.value,
    //       // method: field.forageInfo[i].method.value,
    //       // product: field.forageInfo[i].product.value,
    //       // type: field.forageInfo[i].type.value,
    //       // ipm: field.forageInfo[i].ipm ? field.forageInfo[i].ipm.value : null,
    //       // iwm: field.forageInfo[i].iwm ? field.forageInfo[i].iwm.value : null,
    //       // idm: field.forageInfo[i].idm ? field.forageInfo[i].idm.value : null,
    //       showAllInfo: true,
    //       menu: false,
    //       pickerDate: this.year + "-01",
    //     })
    //   }
    //   return fieldForageData;
    // },
    // isAnyDuplicate(existingForage) {
    //   for (const newForage of this.forageData) {
    //     if (this.isForageDuplicate(existingForage, mapPayloadKeysToUserInput(newForage))) {
    //       return true
    //     }
    //   }
    //   return false
    // },
    // isUntargetedCrop(forage) {
    //   if (this.forageData.length == 0) {
    //     return false
    //   }
    //   const targetedCrops = this.forageData.map(forage => CROP_ID_KEY[forage.crop_id]);
    //   return forage.crop_id.value && !targetedCrops.includes(forage.crop_id.value);
    // },
    // isForageDuplicate(forage1, forage2) {
    //   const duplicateItems = [
    //     forage1.primary_forage.value == forage2.primary_forage.value
    //     // dupeCheck(forage1.rate.value, forage2.rate.value),
    //     // forage1.unit.value == forage2.unit.value,
    //     // forage1.method.value == forage2.method.value,
    //     // forage1.product.value == forage2.product.value,
    //     // forage1.type.value == forage2.type.value
    //   ];
    //   const numDuplicateItems = duplicateItems.filter(Boolean).length;

    //   return (
    //     forage1.crop_id.value == forage2.crop_id.value &&
    //     // forage1.date.value == forage2.date.value &&
    //     numDuplicateItems >= duplicateItems.length - 1
    //   )
    // },
    // isForageNone(forage) {
    //   return (
    //     (forage.primary_forage == 0 || forage.primary_forage == null)
    //     // (forage.rate == 0 || forage.rate == null) &&
    //     // (forage.method == "none" || forage.method == null) &&
    //     // (forage.product == "none" || forage.product == null) &&
    //     // (forage.type == "none" || forage.type == null)
    //   )
    // },
    // mergeFieldPayload(field, foragePayload) {
    //   if (this.forageData.length == 0 || !this.stackPractices) {
    //     return foragePayload;
    //   }
    //   let fieldForageData = this.extractForageData(field);
    //   fieldForageData = fieldForageData.filter(forage => !this.isForageNone(forage));
    //   let mappedData = fieldForageData.map(forage => {
    //     return mapPayloadKeysToUserInput(forage);
    //   });
    //   mappedData = mappedData.filter(existingForage => !foragePayload.some(newForage => this.isForageDuplicate(existingForage, newForage)));

    //   const mergedPayload = foragePayload.concat(mappedData);
    //   return mergedPayload;
    // },
    // handleCropFilterButton(sharedCropPlan) {
    //   if (
    //     JSON.stringify(this.cropSelect) ==
    //     JSON.stringify(sharedCropPlan.crops.split(","))
    //   ) {
    //     this.cropSelect = null
    //     this.filterCrops = []
    //   } else {
    //     this.cropSelect = sharedCropPlan.crops.split(",")
    //     this.filterCrops = [this.mapCropNames(sharedCropPlan.crops)]
    //     if (this.forageData.length > 0) {
    //       const cropValue =
    //         this.cropSelect != null && this.cropSelect.length == 1
    //           ? CROP_KEY[parseInt(this.cropSelect[0])]
    //           : null
    //       this.forageData.forEach(o => {
    //         o.crop_id = cropValue
    //       })
    //     }
    //   }
    // },
    handleRemoveRanching(ranchingIndex) {
      this.ranchingData.splice(ranchingIndex, 1)
      if (this.ranchingData.length == 0) {
        this.stackPractices = false
      }
    },
    blankRanchingData(cropValue = null) {
      return {
        forage: {
          primary_forage: null,
        },
        livestock: {
          species: null,
          animal_details: null,
          average_weight: null,
          /**
           * Setting lbs to the default value because when a user forgets to select the units, it occasionally did not build the jsonb file properly which causes the SQR report to not display properly.
           * Added default value instead of debugging the root cause.
           */
          weight_unit: 'lbs',
        },
        stocking: {
          count_animals: null,
        },
        grazings: {
          date_start: null,
          date_end: null,
          rest_period: null,
          rotational_grazed: null,
          grazed_fed: null,
          feed_ration_type: null,
          feed_ration_percent_crude_protein: null,
          percent_edible_oil: null,
        },
        feed: {
          expected_daily_dmi: null,
          dmi_unit: null,
          additive: null,
          method: null,
          amount: null,
          unit: null,
          days_on_additive: null,
        },
        showAllInfo: true,
        startMenu: false, // boolean for v-menu
        startPicker: this.year + "-01",
        endPicker: this.year + "-01",
        endMenu: false,
      }
    },
    handlePreSave() {
      // if (this.stackPractices) {
      //   this.handleSave()
      // } else {
      //   this.showSaveConfirmModal = true
      // }
      this.showSaveConfirmModal = true
    },
    async handleSave() {
      this.showSaveConfirmModal = false
      this.showSaveView = true
      if (this.fieldsInSelection.length == 0) {
        this.alertErrorModalOpen = true
        return
      }

      // save forage to db
      let ranchingPayload = [];
      this.ranchingData.map(data => {
        ranchingPayload.push({
          year: this.year,
          farm: this.fieldsInSelection[0].farmName,
          field_id: this.fieldsInSelection[0].fieldId,
          field_name: this.fieldsInSelection[0].fieldName,
          acres: '',
          region: '',
          county: '',
          primary_forage: data.forage.primary_forage,
          livestock_species: data.livestock.species,
          livestock_animal_details: data.livestock.animal_details,
          livestock_average_weight: data.livestock.average_weight,
          livestock_weight_unit: data.livestock.weight_unit,
          count_animals: data.stocking.count_animals,
          graze_start_date: data.grazings.date_start,
          graze_end_date: data.grazings.date_end,
          rest_period: data.grazings.rest_period,
          rotational_grazed: data.grazings.rotational_grazed,
          grazed_fed: data.grazings.grazed_fed,
          feed_ration_type: data.grazings.feed_ration_type,
          feed_ration_percent_crude_protein: data.grazings.feed_ration_percent_crude_protein,
          percent_edible_oil: data.grazings.percent_edible_oil,
          feed_expected_daily_dmi: data.feed.expected_daily_dmi,
          feed_dmi_unit: data.feed.dmi_unit,
          feed_additive: data.feed.additive,
          feed_method: data.feed.method,
          feed_amount: data.feed.amount,
          feed_unit: data.feed.unit,
          feed_days_on_additive: data.feed.days_on_additive,
        })
      })

      await UploadsAPI.updateRanching({
        payload: JSON.stringify(ranchingPayload),
      })
        .then(response => {
          this.showSaveView = false
          this.ranchingData = [this.blankRanchingData()];
          this.stackPractices = true
          this.$emit("update-data-complete")
        })
        .catch(error => {
          this.showSaveView = false
          this.ranchingData = [this.blankRanchingData()];
          this.stackPractices = true
          this.$emit("update-data-complete")
        })
    },
    handleSeasonSelection(newYear) {
      this.updateYear(newYear)
    },
    // handleSelectAll(e, resetCropSelect = false) {
    //   if (e == false) this.fieldsInSelection = []
    //   if (e == true) {
    //     this.fieldsInSelection = this.selectedFields
    //       .map(f => f.id)
    //       .filter(f => !this.fieldLocks[f])
    //   }
    //   if (resetCropSelect) {
    //     this.cropSelect = null
    //   }
    // },
    // handleShowRedirect(field) {
    //   this.redirectField = field
    //   this.showRedirectModal = true
    // },
    // handleRedirectConfirm() {
    //   this.$router.push(`/data-spreadsheet-ranch/${this.redirectField.fieldId}`)
    // },
    // handleCloseRedirect() {
    //   this.showRedirectModal = false
    //   this.redirectField = null
    // },
    // handleNoForageClick() {
    //   this.forageData = []
    //   this.stackPractices = false;
    // },
    // handleYesForageClick() {
    //   if (this.forageData.length == 0) {
    //     this.stackPractices = true
    //   }
    //   const cropValue =
    //     this.cropSelect != null && this.cropSelect.length == 1
    //       ? CROP_KEY[parseInt(this.cropSelect[0])]
    //       : null
    //   const newForageData = this.blankRanchingData()
    //   newForageData.crop_id = cropValue
    //   this.forageData = [newForageData]
    // },
    isRanchingComplete(ranching) {
      // return (
      //   forage.crop_id &&
      //   forage.primary_forage
      //   // forage.method &&
      //   // forage.product &&
      //   // forage.type
      // )
      return true
    },
    allRanchingComplete() {
      // if (!this.forageData) return [0]
      // const protCompleteness = this.forageData.map(forage =>
      //   this.isRanchingComplete(forage)
      // )

      // const protMissing = protCompleteness.map((value, index) =>
      //   (value == null || value == false) ? index : ""
      // )
      // const indexOfMissing = protMissing.filter(String)
      // return indexOfMissing
      return [1]
    },
    // forageComplete(forage) {
    //   if (!forage) return null
    //   return (
    //     forage.primary_forage.source == "User Input" &&
    //     forage.primary_forage.value != null
    //     // forage.method.source == "User Input" &&
    //     // forage.method.value != null &&
    //     // forage.product.source == "User Input" &&
    //     // forage.product.value != null &&
    //     // forage.type.source == "User Input" &&
    //     // forage.type.value != null
    //   )
    // },
    // forageNeedsConfirm(forage) {
    //   if (!forage) return null
    //   return (
    //     forage.primary_forage.source != "User Input" &&
    //     forage.primary_forage.value != null
    //     // forage.method.source != "User Input" &&
    //     // forage.method.value != null &&
    //     // forage.product.source != "User Input" &&
    //     // forage.product.value != null &&
    //     // forage.type.source != "User Input" &&
    //     // forage.type.value != null
    //   )
    // },
    // forageMissingData(forage) {
    //   if (!forage) return null
    //   return (
    //     forage.primary_forage.source != "User Input" ||
    //     forage.primary_forage.value == null
    //     // forage.method.source != "User Input" ||
    //     // forage.method.value == null ||
    //     // forage.product.source != "User Input" ||
    //     // forage.product.value == null ||
    //     // forage.type.source != "User Input" ||
    //     // forage.type.value == null
    //   )
    // },
    handleConfirm(field) {
      this.confirmationField = _.cloneDeep(field)
      // this.confirmationApplications = _.cloneDeep(field.forageInfo)
      // this.confirmationApplications.forEach(forage => {
      //   for (const key in forage) {
      //     forage[key].source = "User Input"
      //   }
      // })
      this.showConfirmModal = true
    },
    // async handleModalConfirm() {
    //   this.loadState = "loading"
    //   await this.updateRanching({
    //     fieldId: this.confirmationField.fieldId,
    //     year: this.year,
    //     foragePayload: this.confirmationApplications,
    //   })
    //   this.loadState = "confirmed"
    //   this.$emit("update-data-complete")
    //   await new Promise(resolve => setTimeout(resolve, 1000))
    //   this.handleCloseConfirm()
    // },
    handleCloseConfirm() {
      this.showConfirmModal = false
      this.confirmationField = null
      this.confirmationApplications = null
      this.loadState = null
    },

    handlePastureClick(fieldId) {
      this.$router.push(`/data-spreadsheet-ranch/${fieldId}`)
    }

  },
  mounted() {
    this.tableYear = this.year
    // this.fieldsInSelection = this.selectedFields
    //   .map(f => f.id)
      // .filter(f => !this.fieldLocks[f])
  },
  watch: {
    // cropChoicesFiltered(newChoices) {
    //   for (const practice of this.forageData) {
    //     const existsInNewChoices = newChoices.some(choice => choice === practice.crop_id);
    //     if (!existsInNewChoices) {
    //       practice.crop_id = null;
    //     }
    //   }
    // },
    // filterCrops: {
    //   deep: true,
    //   handler(myArray) {
    //     this.filterCropsReflection = _.cloneDeep(myArray)

    //     let arrayToFilter = _.cloneDeep(this.forageSettingsByField)

    //     if (this.filterCropsReflection.length > 0) {
    //       let cropsToFilter = _.flatten(
    //         this.filterCropsReflection.map(i => {
    //           return i.split(", ")
    //         })
    //       )
    //       arrayToFilter = arrayToFilter.filter(field =>
    //         cropsToFilter.every(c => field.cropNames.indexOf(c) >= 0)
    //       )
    //     }

    //     this.fieldsInSelection = arrayToFilter
    //       .map(f => parseInt(f.fieldId))
    //       .filter(f => !this.fieldLocks[f])
    //   },
    // },
  },
}
</script>
<style scoped>
.row-margin-correct {
  margin-left: 0px;
  margin-right: 0px;
}
.min-step-height {
  /* min-height: 400px; */
}

.text-container {
  width: 200px;
}

.all-button {
  margin-left: 86px;
}

.three {
  width: 3%;
}
.ten {
  width: 10%;
}

.cursor {
  cursor: pointer;
}

.input-holder ::v-deep(.v-label) {
  font-size: 0.8rem;
}

.pasture-filtering-table {
  margin-bottom: 200px;
}

</style>
